import { useCallback, useEffect, useState } from 'react';

/**
 * A prefix to identify session and local storage keys saved using
 * the storage hooks in this application.
 */
export const STORAGE_KEYS_PREFIX = 'hems-admin-portal';

function getValue<T>(key: string, defaultValue: T): T {
    const saved = localStorage.getItem(key) || 'null';
    const initial = JSON.parse(saved);

    return initial || defaultValue;
}

export default function useLocalStorage<T>(
    keyValue: string,
    defaultValue: T,
): {
    storageValue: T;
    setStorageValue: (value: T) => void;
    getStorageValue: () => T;
} {
    const key = `${STORAGE_KEYS_PREFIX}-${keyValue}`;
    const [value, setValue] = useState(() => getValue<T>(key, defaultValue));

    const getStorageValue = useCallback(
        () => getValue<T>(key, defaultValue),
        [key, defaultValue],
    );

    useEffect(() => {
        const item = localStorage.getItem(key);

        if (!item) {
            localStorage.setItem(key, JSON.stringify(defaultValue));
        }

        function handler(e: StorageEvent) {
            if (e.key === key) {
                return;
            }

            const item = localStorage.getItem(key);

            setValue(JSON.parse(item ?? 'null'));
        }

        window.addEventListener('storage', handler);

        return () => {
            window.removeEventListener('storage', handler);
        };
    }, [defaultValue, key]);

    const setValueWrap = useCallback(
        (value: T) => {
            try {
                setValue(value);

                localStorage.setItem(key, JSON.stringify(value));

                if (typeof window !== 'undefined') {
                    window.dispatchEvent(new StorageEvent('storage', { key }));
                }
            } catch (e) {
                // eslint-disable-next-line no-console
                console.error(e);
            }
        },
        [key, setValue],
    );

    return {
        storageValue: value,
        setStorageValue: setValueWrap,
        getStorageValue,
    };
}
