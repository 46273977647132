import {
    useMutation,
    useQuery,
    useQueryClient,
    UseQueryResult,
} from '@tanstack/react-query';

import {
    CRMCustomersApi,
    CRMCustomersBusinessReportsApi,
    CrmCustomersBusinessReportsGetCreationReportsRequest,
    CrmCustomersBusinessReportsGetProductReportsByAggregationFromDateToDateStatesRequest,
    CrmCustomersBusinessReportsGetStatusReportsRequest,
    CrmCustomersBusinessReportsGetTenantReportsRequest,
    CrmCustomersBusinessReportsGetVendorReportsByAggregationFromDateToDateStatesRequest,
    CrmCustomersGetStatsCustomersRequest,
    CrmCustomersGetStatsRequest,
    CRMCustomersHealthApi,
    CrmCustomersHealthGetCustomerSiteHealthRequest,
    CrmCustomersUploadCsvCustomersRequest,
    CrmCustomersUploadCsvForCustomerRequest,
    CrmInstallationsMeterAddMeterDeviceRequest,
    CRMInstallationsMeterApi,
    CrmInstallationsMeterDeleteMeterDeviceRequest,
    CrmInstallationsMeterUpdateMeterDeviceRequest,
    CRMInstallationsPVBatteryApi,
    CrmInstallationsPvBatteryDeleteSmartEnergyDeviceRequest,
    CRMInstallationsWallBoxApi,
    CrmInstallationsWallBoxDeleteWallboxSmartEnergyDeviceRequest,
    CustomerAddModel,
    CustomerResponseModel,
    CustomersOrderByEnum,
    CustomersOrderDirectionEnum,
    CustomerStats,
    CustomerUserResponseModel,
    InstallationsGatewayApi,
    InstallationsGatewayDeleteGatewayDeviceRequest,
    InstallationStatsModel,
    MeterInstallationRequestModel,
    SentInvitationResponseModel,
    SettingsApi,
} from '@swagger-http';

import { createRequestConfiguration } from '@tools/utils/authentication';

interface BusinessReportProps
    extends CrmCustomersBusinessReportsGetCreationReportsRequest {
    isCumulative: boolean;
}

interface CustomerArgs {
    customerId?: string;
    page?: number;
    limit?: number;
    order?: {
        by: CustomersOrderByEnum;
        direction: CustomersOrderDirectionEnum;
    };
    filter?: any;
    searchValue?: string;
    enabled?: boolean;
}

export const useCustomersGetInstallationsStatsQuery = (enabled: boolean) =>
    useQuery<InstallationStatsModel, Error>(
        ['customersInstallationsStats'],
        () =>
            new CRMCustomersApi(
                createRequestConfiguration(),
            ).crmCustomersGetInstallationsStats(),
        { enabled },
    );
interface CustomerGetStatsRequest extends CrmCustomersGetStatsCustomersRequest {
    customerId?: string;
}

export const useCustomerGetInstallationsAccountsQuery = ({
    filters,
    customerId,
}: CustomerGetStatsRequest) =>
    useQuery(
        [
            'customersInstallationsAccounts',
            { filters: filters ?? { installation: [] } },
        ],
        () =>
            new CRMCustomersApi(
                createRequestConfiguration(),
            ).crmCustomersGetStatsCustomers({
                filters,
            }),
        {
            enabled: !customerId,
        },
    );

export const useCustomersStatsChildren = () =>
    useQuery(['customersStatsChildren'], () =>
        new CRMCustomersApi(
            createRequestConfiguration(),
        ).crmCustomersGetStatsCustomers(),
    );

export const useCustomersGetCustomerStatsChildren = ({
    filters,
    customerId,
}: CustomerGetStatsRequest) =>
    useQuery(
        [
            'customersGetCustomerStatsChildren',
            { filters: filters ?? { installation: [] } },
        ],
        () =>
            new CRMCustomersApi(
                createRequestConfiguration(),
            ).crmCustomersGetCustomerStatsChildren({
                filters,
                id: customerId ?? '',
            }),
        {
            enabled: !!customerId,
        },
    );

interface CustomersGetStatsArgs extends CrmCustomersGetStatsRequest {
    enabled?: boolean;
}

export const useCustomersGetStatsQuery = ({
    filters,
    enabled,
}: CustomersGetStatsArgs) =>
    useQuery<CustomerStats, Error>(
        ['customersStats', { filters: filters ?? { installation: [] } }],
        () =>
            new CRMCustomersApi(
                createRequestConfiguration(),
            ).crmCustomersGetStats({
                filters: filters ?? undefined,
            }),
        {
            // @ts-ignore Wrong or unspecified swagger model: EONFEH-16070
            enabled: enabled && !!filters?.installation?.length,
        },
    );

export const useCustomersBusinessReportQuery = ({
    aggregation,
    fromDate,
    toDate,
    isCumulative,
}: BusinessReportProps) => {
    return useQuery(
        [
            'customersBusinessReports',
            { aggregation, fromDate, toDate, isCumulative },
        ],
        () => {
            const requestParams = {
                aggregation,
                fromDate,
                toDate,
            };
            const crmCustomersBusinessReportsApi =
                new CRMCustomersBusinessReportsApi(
                    createRequestConfiguration(),
                );
            return isCumulative
                ? crmCustomersBusinessReportsApi.crmCustomersBusinessReportsGetCreationReportsIncremental(
                      requestParams,
                  )
                : crmCustomersBusinessReportsApi.crmCustomersBusinessReportsGetCreationReports(
                      requestParams,
                  );
        },
    );
};

export const useCustomersTenantReports = ({
    aggregation,
    fromDate,
    toDate,
}: CrmCustomersBusinessReportsGetTenantReportsRequest) => {
    return useQuery(
        ['customersTenantsReports', { aggregation, fromDate, toDate }],
        () => {
            const requestParams = {
                aggregation,
                fromDate,
                toDate,
            };
            const crmCustomersBusinessReportsApi =
                new CRMCustomersBusinessReportsApi(
                    createRequestConfiguration(),
                );
            return crmCustomersBusinessReportsApi.crmCustomersBusinessReportsGetTenantReports(
                requestParams,
            );
        },
    );
};

export const useDevicesConfigurations = () => {
    return useQuery(['devicesConfigurationsReport'], () => {
        const crmCustomersBusinessReportsApi =
            new CRMCustomersBusinessReportsApi(createRequestConfiguration());
        return crmCustomersBusinessReportsApi.crmCustomersBusinessReportsGetManagerReportDevicesConfigurations();
    });
};

export const useUsersActivities = () => {
    return useQuery(['usersActivitiesReport'], () => {
        const crmCustomersBusinessReportsApi =
            new CRMCustomersBusinessReportsApi(createRequestConfiguration());
        return crmCustomersBusinessReportsApi.crmCustomersBusinessReportsGetManagerReportUsersActivity();
    });
};

export const useCustomersProductReportsBasedOnStates = ({
    aggregation,
    fromDate,
    toDate,
    states,
}: CrmCustomersBusinessReportsGetProductReportsByAggregationFromDateToDateStatesRequest) => {
    return useQuery(
        [
            'customersProductsReportsBasedOnStates',
            { aggregation, fromDate, toDate, states },
        ],
        () => {
            const requestParams = {
                aggregation,
                fromDate,
                toDate,
                states,
            };
            const crmCustomersBusinessReportsApi =
                new CRMCustomersBusinessReportsApi(
                    createRequestConfiguration(),
                );
            return crmCustomersBusinessReportsApi.crmCustomersBusinessReportsGetProductReportsByAggregationFromDateToDateStates(
                requestParams,
            );
        },
    );
};

export const useGetVendors = () => {
    return useQuery(['customersVendors'], () => {
        const settingsApi = new SettingsApi(createRequestConfiguration());
        return settingsApi.settingsGetVendors();
    });
};

export const useCustomersVendorReportsBasedOnStates = ({
    aggregation,
    fromDate,
    toDate,
    states,
}: CrmCustomersBusinessReportsGetVendorReportsByAggregationFromDateToDateStatesRequest) => {
    return useQuery(
        [
            'customersVendorsReportsBasedOnStates',
            { aggregation, fromDate, toDate, states },
        ],
        () => {
            const requestParams = {
                aggregation,
                fromDate,
                toDate,
                states,
            };
            const crmCustomersBusinessReportsApi =
                new CRMCustomersBusinessReportsApi(
                    createRequestConfiguration(),
                );
            return crmCustomersBusinessReportsApi.crmCustomersBusinessReportsGetVendorReportsByAggregationFromDateToDateStates(
                requestParams,
            );
        },
    );
};

export const useCustomersStatusReports = ({
    aggregation,
    fromDate,
    toDate,
}: CrmCustomersBusinessReportsGetStatusReportsRequest) => {
    return useQuery(
        ['customersStatusesReports', { aggregation, fromDate, toDate }],
        () => {
            const requestParams = {
                aggregation,
                fromDate,
                toDate,
            };
            const crmCustomersBusinessReportsApi =
                new CRMCustomersBusinessReportsApi(
                    createRequestConfiguration(),
                );
            return crmCustomersBusinessReportsApi.crmCustomersBusinessReportsGetStatusReports(
                requestParams,
            );
        },
    );
};

export const useCrmCustomersGetCustomersQuery = ({
    page,
    limit,
    order,
    filter,
    searchValue,
    enabled,
}: CustomerArgs) => {
    return useQuery({
        queryKey: ['getCrmCustomers', page, limit, order, filter, searchValue],
        enabled,
        queryFn: async () => {
            const response = new CRMCustomersApi(
                createRequestConfiguration(),
            ).crmCustomersGetCustomers({
                page: page ?? 0,
                limit: limit ?? -1,
                order,
                filter,
                searchValue,
            });

            return response;
        },
    });
};

export const useCrmCustomersHealthGetSitesHealthMutation = (
    retry?: boolean,
) => {
    return useMutation({
        mutationKey: ['getCrmCustomersHealth'],
        retry,
        mutationFn: async (customerIds: string[]) => {
            const payload = {
                sitesHealthRequestBody: {
                    customerIds: [...new Set(customerIds)],
                },
            };
            const response = new CRMCustomersHealthApi(
                createRequestConfiguration(),
                //@ts-ignore
                //TODO: Fix Swagger interface from Set to string[]
            ).crmCustomersHealthGetSitesHealth(payload);
            return response;
        },
    });
};

export const useSingleCustomerDetails = (
    customerId: string,
    enabled?: boolean,
): UseQueryResult<CustomerResponseModel> =>
    useQuery({
        queryKey: ['getCrmCustomer', customerId],
        enabled,
        queryFn: async () =>
            await new CRMCustomersApi(
                createRequestConfiguration(),
            ).crmCustomersGetCustomer({ id: customerId }),
    });

export const useCrmCustomersHealthGetCustomerSiteHealthMutation = (
    retry?: boolean,
) => {
    return useMutation({
        mutationKey: ['getCrmSingleCustomerHealth'],
        retry,
        mutationFn: async (customerId: string) => {
            const response = new CRMCustomersHealthApi(
                createRequestConfiguration(),
            ).crmCustomersHealthGetCustomerSiteHealth({
                customerId,
            } as CrmCustomersHealthGetCustomerSiteHealthRequest);

            return response;
        },
    });
};

export const useCustomerUsers = (
    id: string,
): UseQueryResult<CustomerUserResponseModel[]> =>
    useQuery({
        queryKey: ['getCrmUsersCustomer', id],
        queryFn: async () =>
            await new CRMCustomersApi(
                createRequestConfiguration(),
            ).crmCustomersGetCustomerUsers({ id }),
    });

export const useCustomerHistoryOfSentUserInvitations = (
    id: string,
): UseQueryResult<SentInvitationResponseModel[]> =>
    useQuery({
        queryKey: ['getHistoryOfSentUserInvitations', id],
        queryFn: async () =>
            await new CRMCustomersApi(
                createRequestConfiguration(),
            ).crmCustomersGetSendAccountUserInvitations({ id }),
    });

export const useCreateCustomer = () => {
    return useMutation({
        mutationKey: ['useCreateCustomer'],
        mutationFn: async (payload: CustomerAddModel) => {
            return new CRMCustomersApi(
                createRequestConfiguration(),
            ).crmCustomersAddAccountUser({ customerAddModel: payload });
        },
    });
};

export const useCustomerSites = (customerId: string, enabled?: boolean) =>
    useQuery({
        queryKey: ['crmCustomersCustomerGetSites', customerId],
        enabled,
        queryFn: async () =>
            await new CRMCustomersApi(
                createRequestConfiguration(),
            ).crmCustomersCustomerGetSites({ id: customerId }),
    });

export const useGetSingleCustomerAccessToken = (
    customerId: string,
    enabled?: boolean,
) =>
    useQuery({
        queryKey: ['getSingleCustomerAccessToken', customerId],
        enabled,
        queryFn: async () =>
            await new CRMCustomersApi(
                createRequestConfiguration(),
            ).crmCustomersGetAuthTokens({ id: customerId }),
    });

export const useGetSingleCustomerIdpProvider = (customerId: string) =>
    useQuery(
        ['getSingleCustomerIdpProvider', { customerId }],
        async () =>
            await new CRMCustomersApi(
                createRequestConfiguration(),
            ).crmCustomersCustomerGetIdpProvider({ id: customerId }),
    );

export const useAddInstallationsMeterDeviceMutation = (customerId: string) => {
    const queryClient = useQueryClient();

    return useMutation({
        mutationKey: ['crmInstallationsMeterAddMeterDevice', customerId],
        mutationFn: async (smartMeterData: MeterInstallationRequestModel) => {
            const data: CrmInstallationsMeterAddMeterDeviceRequest = {
                id: customerId,
                meterInstallationRequestModel: smartMeterData,
            };

            return await new CRMInstallationsMeterApi(
                createRequestConfiguration(),
            ).crmInstallationsMeterAddMeterDevice(data);
        },
        onSuccess: () => {
            queryClient.invalidateQueries({
                queryKey: ['getSmartMeterInstallationStatus', customerId],
            });
        },
    });
};

export const useCustomersCompareCustomerData = (
    customerId: string,
    configId: string,
    date?: string,
) =>
    useQuery({
        queryKey: ['customersCompareCustomerData', customerId, date],
        queryFn: async () =>
            await new CRMCustomersApi(
                createRequestConfiguration(),
            ).crmCustomersCompareCustomerData({
                customerId: customerId,
                configId: configId,
                date: date,
            }),
    });

export const useUpdateSmartMeterInstallation = (
    customerId: string,
    retry?: boolean,
) => {
    const queryClient = useQueryClient();

    return useMutation({
        retry,
        mutationKey: ['updateSmartMeterInstallation', customerId],
        onSuccess: () => {
            queryClient.invalidateQueries({
                queryKey: ['getSmartMeterInstallationStatus', customerId],
            });
        },
        mutationFn: async ({
            smartMeterData,
            configurationId,
        }: {
            smartMeterData: MeterInstallationRequestModel;
            configurationId: string;
        }) => {
            const data: CrmInstallationsMeterUpdateMeterDeviceRequest = {
                id: customerId,
                configurationId,
                meterInstallationRequestModel: smartMeterData,
            };

            const response = await new CRMInstallationsMeterApi(
                createRequestConfiguration(),
            ).crmInstallationsMeterUpdateMeterDevice(data);

            return response;
        },
    });
};

export const useDeleteSmartMeterInstallation = (
    customerId: string,
    retry?: boolean,
) => {
    const queryClient = useQueryClient();

    return useMutation({
        retry,
        mutationKey: ['deleteSmartMeterInstallation', customerId],
        onSuccess: () => {
            queryClient.invalidateQueries({
                queryKey: ['getSmartMeterInstallationStatus', customerId],
            });
        },
        mutationFn: async ({
            configurationId,
        }: {
            configurationId: string;
        }) => {
            const data: CrmInstallationsMeterDeleteMeterDeviceRequest = {
                id: customerId,
                configurationId,
            };

            const response = await new CRMInstallationsMeterApi(
                createRequestConfiguration(),
            ).crmInstallationsMeterDeleteMeterDevice(data);

            return response;
        },
    });
};

export const useDeletePvBatteryInstallation = (
    customerId: string,
    retry?: boolean,
) => {
    const queryClient = useQueryClient();

    return useMutation({
        retry,
        mutationKey: ['deletePvBatteryInstallation', customerId],
        onSuccess: () => {
            queryClient.invalidateQueries({
                queryKey: ['getPvBatteryInstallationStatus', customerId],
            });
        },
        mutationFn: async ({
            configurationId,
        }: {
            configurationId: string;
        }) => {
            const data: CrmInstallationsPvBatteryDeleteSmartEnergyDeviceRequest =
                {
                    id: customerId,
                    configurationId,
                };

            const response = await new CRMInstallationsPVBatteryApi(
                createRequestConfiguration(),
            ).crmInstallationsPvBatteryDeleteSmartEnergyDevice(data);

            return response;
        },
    });
};

export const useDeleteWallboxInstallation = (
    customerId: string,
    retry?: boolean,
) => {
    const queryClient = useQueryClient();

    return useMutation({
        retry,
        mutationKey: ['deleteWallboxInstallation', customerId],
        onSuccess: () => {
            queryClient.invalidateQueries({
                queryKey: ['getWallboxInstallationStatus', customerId],
            });
        },
        mutationFn: async ({
            configurationId,
        }: {
            configurationId: string;
        }) => {
            const data: CrmInstallationsWallBoxDeleteWallboxSmartEnergyDeviceRequest =
                {
                    id: customerId,
                    deviceId: configurationId,
                };

            const response = await new CRMInstallationsWallBoxApi(
                createRequestConfiguration(),
            ).crmInstallationsWallBoxDeleteWallboxSmartEnergyDevice(data);

            return response;
        },
    });
};

export const useDeleteGatewayInstallation = (
    customerId: string,
    retry?: boolean,
) => {
    const queryClient = useQueryClient();

    return useMutation({
        retry,
        mutationKey: ['deleteGatewayDevice', customerId],
        onSuccess: () => {
            queryClient.invalidateQueries({
                queryKey: ['getGatewayDevices', customerId],
            });
        },
        mutationFn: async ({
            configurationId,
        }: {
            configurationId: string;
        }) => {
            const data: InstallationsGatewayDeleteGatewayDeviceRequest = {
                id: customerId,
                installationId: configurationId,
            };

            const response = await new InstallationsGatewayApi(
                createRequestConfiguration(),
            ).installationsGatewayDeleteGatewayDevice(data);

            return response;
        },
    });
};

export const useGetCustomerCustomers = ({
    customerId,
    page,
    limit,
    order,
    filter,
    searchValue,
    enabled,
}: CustomerArgs) => {
    return useQuery({
        queryKey: [
            'getCustomerCustomers',
            customerId,
            page,
            limit,
            order,
            filter,
            searchValue,
        ],

        enabled: enabled && !!customerId,
        queryFn: async () =>
            await new CRMCustomersApi(
                createRequestConfiguration(),
            ).crmCustomersGetCustomerCustomers({
                id: customerId ?? '',
                page,
                limit,
                order,
                filter,
                searchValue,
            }),
    });
};

export const useCustomersGetCustomerInstallationsStats = (customerId: string) =>
    useQuery<InstallationStatsModel, Error>(
        ['customersInstallationsStats', customerId],
        () =>
            new CRMCustomersApi(
                createRequestConfiguration(),
            ).crmCustomersGetCustomerInstallationsStats({
                id: customerId,
            }),
        { enabled: !!customerId },
    );

export const useGetCustomerStats = (customerId?: string, filters?: object) =>
    useQuery(
        [
            'getCustomerStats',
            customerId,
            ,
            { filters: filters ?? { installation: [] } },
        ],
        () =>
            new CRMCustomersApi(
                createRequestConfiguration(),
            ).crmCustomersGetCustomerStats({
                id: customerId ?? '',
                filters: filters,
            }),
        //@ts-ignore
        { enabled: !!customerId && !!filters?.installation?.length },
    );

export const useUploadCreateCustomersCsvMutation = (retry?: boolean) => {
    return useMutation({
        retry,
        mutationKey: ['uploadCreateCustomersCsv'],
        mutationFn: async (payload: { id?: string; csvData?: Blob }) => {
            return payload?.id
                ? await new CRMCustomersApi(
                      createRequestConfiguration(),
                  ).crmCustomersUploadCsvForCustomer(
                      payload as CrmCustomersUploadCsvForCustomerRequest,
                  )
                : await new CRMCustomersApi(
                      createRequestConfiguration(),
                  ).crmCustomersUploadCsvCustomers(
                      payload as CrmCustomersUploadCsvCustomersRequest,
                  );
        },
    });
};
