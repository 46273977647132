import { useMutation, useQuery } from '@tanstack/react-query';

import { AuthenticationApi, AuthenticationResponseModel } from '@swagger-http';

import { createRequestConfiguration } from '@tools/utils/authentication';

export const useAuthenticationLinkQuery = () => {
    return useQuery({
        queryKey: ['authenticationLink'],
        queryFn: async () => {
            const response = await new AuthenticationApi(
                createRequestConfiguration(),
            ).authenticationLink();
            return response;
        },
    });
};

const signInMultiFactor = async (
    token: string,
): Promise<AuthenticationResponseModel> => {
    const data = await new AuthenticationApi(
        createRequestConfiguration(),
    ).authenticationSignInMultiFactor({
        authenticationRequestModel: { token },
    });
    return data;
};
export const useMutateSignInMultiFactor = () => {
    return useMutation(signInMultiFactor);
};

const signIn = async (
    authorization: string,
): Promise<AuthenticationResponseModel> => {
    const data = await new AuthenticationApi(
        createRequestConfiguration(),
    ).authenticationLogin(
        { authorization },
        {
            credentials: 'include',
        },
    );
    return data;
};
export const useMutateSignIn = (authorization: string) => {
    return useMutation(() => signIn(authorization));
};

const signOut = async (): Promise<void> => {
    return await new AuthenticationApi(
        createRequestConfiguration(),
    ).authenticationLogout({ credentials: 'include' });
};
export const useMutateSignOut = () => {
    return useMutation(signOut);
};
