/* tslint:disable */
/* eslint-disable */
/**
 * E.ON Home Admin Portal - API
 * # Introduction    This API provides access to admin and customer management.
 *
 * The version of the OpenAPI document: 1.0
 * Contact: sebastian.eggers@eon.com
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */


import * as runtime from '../runtime';
import type {
  BadRequest,
  Forbidden,
  GatewayDeviceInstallationRequestModel,
  GatewayResponseModel,
  InstallationResponseModel,
  NotFound,
  Unauthorized,
} from '../models';

export interface InstallationsGatewayAddGatewayDeviceRequest {
    id: string;
    gatewayDeviceInstallationRequestModel: GatewayDeviceInstallationRequestModel;
}

export interface InstallationsGatewayDeleteGatewayDeviceRequest {
    id: string;
    installationId: string;
}

export interface InstallationsGatewayGetGatewayDevicesRequest {
    id: string;
}

export interface InstallationsGatewayScanGatewayDevicesRequest {
    id: string;
    installationId: string;
}

/**
 * 
 */
export class InstallationsGatewayApi extends runtime.BaseAPI {

    /**
     * Add a gateway configuration
     */
    async installationsGatewayAddGatewayDeviceRaw(requestParameters: InstallationsGatewayAddGatewayDeviceRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<runtime.ApiResponse<GatewayResponseModel>> {
        if (requestParameters.id === null || requestParameters.id === undefined) {
            throw new runtime.RequiredError('id','Required parameter requestParameters.id was null or undefined when calling installationsGatewayAddGatewayDevice.');
        }

        if (requestParameters.gatewayDeviceInstallationRequestModel === null || requestParameters.gatewayDeviceInstallationRequestModel === undefined) {
            throw new runtime.RequiredError('gatewayDeviceInstallationRequestModel','Required parameter requestParameters.gatewayDeviceInstallationRequestModel was null or undefined when calling installationsGatewayAddGatewayDevice.');
        }

        const queryParameters: any = {};

        const headerParameters: runtime.HTTPHeaders = {};

        headerParameters['Content-Type'] = 'application/json';

        if (this.configuration && this.configuration.accessToken) {
            // oauth required
            headerParameters["Authorization"] = await this.configuration.accessToken("OAuth2", []);
        }

        const response = await this.request({
            path: `/api/v1/customers/{id}/installations/gateway`.replace(`{${"id"}}`, encodeURIComponent(String(requestParameters.id))),
            method: 'POST',
            headers: headerParameters,
            query: queryParameters,
            body: requestParameters.gatewayDeviceInstallationRequestModel,
        }, initOverrides);

        return new runtime.JSONApiResponse(response);
    }

    /**
     * Add a gateway configuration
     */
    async installationsGatewayAddGatewayDevice(requestParameters: InstallationsGatewayAddGatewayDeviceRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<GatewayResponseModel> {
        const response = await this.installationsGatewayAddGatewayDeviceRaw(requestParameters, initOverrides);
        return await response.value();
    }

    /**
     * Remove a gateway configuration
     */
    async installationsGatewayDeleteGatewayDeviceRaw(requestParameters: InstallationsGatewayDeleteGatewayDeviceRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<runtime.ApiResponse<void>> {
        if (requestParameters.id === null || requestParameters.id === undefined) {
            throw new runtime.RequiredError('id','Required parameter requestParameters.id was null or undefined when calling installationsGatewayDeleteGatewayDevice.');
        }

        if (requestParameters.installationId === null || requestParameters.installationId === undefined) {
            throw new runtime.RequiredError('installationId','Required parameter requestParameters.installationId was null or undefined when calling installationsGatewayDeleteGatewayDevice.');
        }

        const queryParameters: any = {};

        const headerParameters: runtime.HTTPHeaders = {};

        if (this.configuration && this.configuration.accessToken) {
            // oauth required
            headerParameters["Authorization"] = await this.configuration.accessToken("OAuth2", []);
        }

        const response = await this.request({
            path: `/api/v1/customers/{id}/installations/gateway/{installationId}`.replace(`{${"id"}}`, encodeURIComponent(String(requestParameters.id))).replace(`{${"installationId"}}`, encodeURIComponent(String(requestParameters.installationId))),
            method: 'DELETE',
            headers: headerParameters,
            query: queryParameters,
        }, initOverrides);

        return new runtime.VoidApiResponse(response);
    }

    /**
     * Remove a gateway configuration
     */
    async installationsGatewayDeleteGatewayDevice(requestParameters: InstallationsGatewayDeleteGatewayDeviceRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<void> {
        await this.installationsGatewayDeleteGatewayDeviceRaw(requestParameters, initOverrides);
    }

    /**
     * Get all gateway configurations
     */
    async installationsGatewayGetGatewayDevicesRaw(requestParameters: InstallationsGatewayGetGatewayDevicesRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<runtime.ApiResponse<Array<InstallationResponseModel>>> {
        if (requestParameters.id === null || requestParameters.id === undefined) {
            throw new runtime.RequiredError('id','Required parameter requestParameters.id was null or undefined when calling installationsGatewayGetGatewayDevices.');
        }

        const queryParameters: any = {};

        const headerParameters: runtime.HTTPHeaders = {};

        if (this.configuration && this.configuration.accessToken) {
            // oauth required
            headerParameters["Authorization"] = await this.configuration.accessToken("OAuth2", []);
        }

        const response = await this.request({
            path: `/api/v1/customers/{id}/installations/gateway`.replace(`{${"id"}}`, encodeURIComponent(String(requestParameters.id))),
            method: 'GET',
            headers: headerParameters,
            query: queryParameters,
        }, initOverrides);

        return new runtime.JSONApiResponse(response);
    }

    /**
     * Get all gateway configurations
     */
    async installationsGatewayGetGatewayDevices(requestParameters: InstallationsGatewayGetGatewayDevicesRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<Array<InstallationResponseModel>> {
        const response = await this.installationsGatewayGetGatewayDevicesRaw(requestParameters, initOverrides);
        return await response.value();
    }

    /**
     * Installation scan appliances
     */
    async installationsGatewayScanGatewayDevicesRaw(requestParameters: InstallationsGatewayScanGatewayDevicesRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<runtime.ApiResponse<void>> {
        if (requestParameters.id === null || requestParameters.id === undefined) {
            throw new runtime.RequiredError('id','Required parameter requestParameters.id was null or undefined when calling installationsGatewayScanGatewayDevices.');
        }

        if (requestParameters.installationId === null || requestParameters.installationId === undefined) {
            throw new runtime.RequiredError('installationId','Required parameter requestParameters.installationId was null or undefined when calling installationsGatewayScanGatewayDevices.');
        }

        const queryParameters: any = {};

        const headerParameters: runtime.HTTPHeaders = {};

        if (this.configuration && this.configuration.accessToken) {
            // oauth required
            headerParameters["Authorization"] = await this.configuration.accessToken("OAuth2", []);
        }

        const response = await this.request({
            path: `/api/v1/customers/{id}/installations/gateway/{installationId}/scan`.replace(`{${"id"}}`, encodeURIComponent(String(requestParameters.id))).replace(`{${"installationId"}}`, encodeURIComponent(String(requestParameters.installationId))),
            method: 'POST',
            headers: headerParameters,
            query: queryParameters,
        }, initOverrides);

        return new runtime.VoidApiResponse(response);
    }

    /**
     * Installation scan appliances
     */
    async installationsGatewayScanGatewayDevices(requestParameters: InstallationsGatewayScanGatewayDevicesRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<void> {
        await this.installationsGatewayScanGatewayDevicesRaw(requestParameters, initOverrides);
    }

}
