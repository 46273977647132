import { Control, Controller } from 'react-hook-form';

import { TextField, TextFieldProps, TextFieldVariants } from '@mui/material';

import { ValidateOptions } from '@tools/utils/validations';

export interface Props extends Omit<TextFieldProps, 'variant'> {
    name: string;
    control: Control<any>;
    variant?: TextFieldVariants;
    required?: boolean;
    errorMessage?: string;
    validateOptions?: ValidateOptions;
    handleChange?: (
        event?: React.ChangeEventHandler<
            HTMLTextAreaElement | HTMLInputElement
        >,
    ) => void;
    validateField?: (
        value: string,
        options?: ValidateOptions,
    ) => string | undefined;
}

export const ControlledTextField = ({
    id,
    name,
    type,
    variant,
    control,
    required,
    errorMessage,
    defaultValue,
    validateOptions,
    validateField,
    ...props
}: Props) => {
    return (
        <Controller
            name={name}
            control={control}
            rules={
                validateField && {
                    validate: (value) => validateField(value, validateOptions),
                }
            }
            defaultValue={defaultValue ?? ''}
            render={({ field }) => (
                <TextField
                    {...field}
                    {...props}
                    id={id ?? name}
                    type={type ?? 'text'}
                    error={!!errorMessage}
                    variant={variant ?? 'standard'}
                    fullWidth
                    onBlur={(event) => {
                        field.onBlur();
                        props?.onBlur?.(event);
                    }}
                    required={required}
                    helperText={props?.helperText ?? errorMessage ?? ''}
                />
            )}
        />
    );
};
