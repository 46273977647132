import loadable, { LoadableComponent } from '@loadable/component';

import { Loader } from '@eon-home/react-library';

const load = (path: string, isContainer = false): LoadableComponent<any> =>
    loadable(
        () => import(`../${isContainer ? 'containers' : 'common'}/${path}`),
        { fallback: <Loader /> },
    );

export const NotFound = load('not-found');
export const LoginPage = load('login', true);
export const DashboardPage = load('dashboard/page', true);
export const ReportsPage = load('reports', true);
export const ProfilePage = load('profile/page', true);
export const AccountPage = load('account/page', true);
export const CustomerPage = load('customer/page', true);
export const CustomersPage = load('customers/page', true);
export const IntegrationSettingsPage = load('integration-settings/page', true);
export const FeatureGroupsPage = load('feature-groups/page', true);
export const CMSPage = load('cms/page', true);
export const DataStore = load('data-store');
