import { useMemo } from 'react';

import { Snackbar, SnackbarOrigin, Stack } from '@mui/material';

import Toast from '@components/notifications/toast';
import useToastList from '@components/notifications/toast-notifications-provider';

const defaultAnchorOrigin: SnackbarOrigin = {
    vertical: 'top',
    horizontal: 'right',
};

export const ToastNotificationRenderer = () => {
    const { toastList } = useToastList();

    const isOpen = useMemo(() => {
        return !!toastList?.[0];
    }, [toastList]);

    if (!toastList || toastList.length === 0) {
        return null;
    }

    return (
        <Snackbar
            autoHideDuration={null}
            transitionDuration={0}
            anchorOrigin={defaultAnchorOrigin}
            open={isOpen}
        >
            <Stack flexDirection="column" gap={1}>
                {toastList.map((toast) => (
                    <Toast key={toast.key} toast={toast} />
                ))}
            </Stack>
        </Snackbar>
    );
};
