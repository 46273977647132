import { ReactNode, useMemo } from 'react';

import { useTranslation } from 'react-i18next';
import { useLocation } from 'react-router';
import { NavLink } from 'react-router-dom';

import ArticleIcon from '@mui/icons-material/Article';
import DashboardIcon from '@mui/icons-material/Dashboard';
import ExtensionIcon from '@mui/icons-material/Extension';
import FitbitIcon from '@mui/icons-material/Fitbit';
import GroupIcon from '@mui/icons-material/Group';
import HelpCenterIcon from '@mui/icons-material/HelpCenter';
import HistoryIcon from '@mui/icons-material/History';
import ManageAccountsIcon from '@mui/icons-material/ManageAccounts';
import SettingsIcon from '@mui/icons-material/Settings';
import SettingsApplicationsIcon from '@mui/icons-material/SettingsApplications';
import {
    Chip,
    ListItemIcon,
    ListItemText,
    MenuItem,
    MenuList,
    Paper,
} from '@mui/material';

import { Routes } from '@api/tools';

import { useCrmCustomersGetCustomersQuery } from '@queries/customers';
import { useAccountGetAccountUsersQuery } from '@queries/users';
import { useGetVendorsHealth } from '@queries/vendors';
import { checkForScopes } from '@tools/utils/scopes';

import useAuthContext from '@context/auth-provider';
import {
    cmsReadScope,
    featuresReadScope,
    settingsReadScope,
} from '@tools/constants';
import { Scope } from '@tools/enums';

import styles from '@components/menu/index.module.scss';

type MenuItem = {
    id: string;
    title: string;
    icon: ReactNode;
    link: string;
    label?: ReactNode;
    isHidden?: boolean;
};

type MenuItemGroup = {
    heading?: string;
    items: MenuItem[];
    isHidden?: boolean;
};

export const Menu = () => {
    const { t } = useTranslation();
    const { pathname } = useLocation();
    const { isLoggedIn } = useAuthContext();

    const { data: vendors } = useGetVendorsHealth(isLoggedIn);

    const { data: customers, isLoading: isLoadingCustomers } =
        useCrmCustomersGetCustomersQuery({
            page: 0,
            limit: 0,
            order: undefined,
            filter: {},
            searchValue: '',
            enabled: isLoggedIn,
        });

    const hasPolicy = useMemo(
        () => checkForScopes([Scope.ACCOUNT_USERS_READ]),
        [],
    );

    const { data: members, isLoading: isLoadingMembers } =
        useAccountGetAccountUsersQuery(hasPolicy, 0, 0, undefined, isLoggedIn);

    const expiredVendorsCount = useMemo(() => {
        if (!vendors) {
            return 0;
        }

        return vendors.filter((vendor) => vendor.health !== 'valid').length;
    }, [vendors]);

    const menuItemGroups: MenuItemGroup[] = useMemo(
        () => [
            {
                heading: t('common.applicationName'),
                items: [
                    {
                        id: 'dashboard-menu',
                        title: t('menu.dashboard'),
                        icon: <DashboardIcon fontSize="small" />,
                        link: Routes.DASHBOARD,
                    },
                    {
                        id: 'business-report-menu',
                        title: t('menu.businessReport'),
                        icon: <ExtensionIcon fontSize="small" />,
                        link: Routes.BUSINESS_REPORTS,
                        isHidden: !checkForScopes([
                            Scope.BUSINESS_REPORTS_READ,
                        ]),
                    },
                    {
                        id: 'members-menu',
                        title: t('menu.members'),
                        icon: <GroupIcon fontSize="small" />,
                        link: Routes.ACCOUNT_MEMBERS,
                        isHidden: !hasPolicy,
                        label: !isLoadingMembers ? (
                            <Chip
                                label={members?.count}
                                size="small"
                                sx={{
                                    borderRadius: 2,
                                    color: 'white',
                                    bgcolor: 'grey',
                                }}
                            />
                        ) : undefined,
                    },
                    {
                        id: 'customers-menu',
                        title: t('menu.customers'),
                        icon: <ManageAccountsIcon fontSize="small" />,
                        link: Routes.CUSTOMERS,
                        isHidden: !checkForScopes([Scope.CUSTOMERS_READ]),
                        label: !isLoadingCustomers ? (
                            <Chip
                                label={customers?.count}
                                size="small"
                                sx={{
                                    borderRadius: 2,
                                    color: 'white',
                                    bgcolor: 'grey',
                                }}
                            />
                        ) : undefined,
                    },
                    {
                        id: 'integration-settings-menu',
                        title: t('menu.integrationSettings'),
                        icon: <SettingsIcon fontSize="small" />,
                        link: Routes.INTEGRATION_SETTINGS,
                        label:
                            checkForScopes([Scope.SYSTEM_SETTINGS_READ]) &&
                            expiredVendorsCount ? (
                                <Chip
                                    label={expiredVendorsCount}
                                    size="small"
                                    color="primary"
                                    sx={{ borderRadius: 2, color: 'white' }}
                                />
                            ) : undefined,
                        isHidden: !checkForScopes(settingsReadScope),
                    },
                    {
                        id: 'feature-groups-menu',
                        title: t('menu.featureGroups'),
                        icon: <FitbitIcon fontSize="small" />,
                        link: Routes.FEATURE_GROUPS,
                        isHidden: !checkForScopes(featuresReadScope),
                    },
                ],
            },
            {
                heading: t('common.activity'),
                isHidden: !checkForScopes([Scope.ACTIVITIES_READ]),
                items: [
                    {
                        id: 'account-activityLog-menu',
                        title: t('menu.accountActivityLog'),
                        icon: <HistoryIcon fontSize="small" />,
                        link: Routes.ACCOUNT_ACTIVITY_LOG,
                    },
                    {
                        id: 'my-activity-log-menu',
                        title: t('menu.myActivityLog'),
                        icon: <HistoryIcon fontSize="small" />,
                        link: Routes.PROFILE_ACTIVITY_LOG,
                    },
                ],
            },
            {
                heading: t('common.contentManagementSystem'),
                isHidden: !checkForScopes(cmsReadScope),
                items: [
                    {
                        id: 'content-menu',
                        title: t('menu.content'),
                        icon: <ArticleIcon fontSize="small" />,
                        link: Routes.CMS_CONTENT,
                        isHidden: !checkForScopes([Scope.CMS_CONTENT_READ]),
                    },
                    {
                        id: 'schemas-menu',
                        title: t('menu.schemas'),
                        icon: <SettingsApplicationsIcon fontSize="small" />,
                        link: Routes.CMS_SCHEMAS,
                        isHidden: !checkForScopes([Scope.CMS_SETTINGS_READ]),
                    },
                    {
                        id: 'instructions-menu',
                        title: t('menu.instructions'),
                        icon: <HelpCenterIcon fontSize="small" />,
                        link: Routes.CMS_INSTRUCTIONS,
                        isHidden: !checkForScopes([Scope.CMS_CONTENT_READ]),
                    },
                ],
            },
        ],
        [
            t,
            hasPolicy,
            isLoadingMembers,
            members?.count,
            isLoadingCustomers,
            customers?.count,
            expiredVendorsCount,
        ],
    );

    return (
        <Paper
            sx={{
                width: 320,
                maxWidth: '100%',
                height: '100%',
            }}
            className={`main-menu ${styles.menu}`}
        >
            <MenuList>
                {menuItemGroups
                    .filter((menuItem) => !menuItem.isHidden)
                    .map((group) => [
                        group.heading && (
                            <MenuItem
                                disabled
                                divider
                                className={`${styles.heading} ${styles.heading__title}`}
                            >
                                <ListItemText>{group.heading}</ListItemText>
                            </MenuItem>
                        ),
                        group.items.map((item) => {
                            const itemLinkWithoutDashboard = item.link
                                .split('/')
                                .slice(2)
                                .join('/');

                            const isActive =
                                pathname === item.link ||
                                (!!itemLinkWithoutDashboard &&
                                    pathname.includes(
                                        itemLinkWithoutDashboard,
                                    ));

                            return item.isHidden ? null : (
                                <li
                                    key={item.title}
                                    className={styles.item}
                                    data-testid={item.id}
                                >
                                    <MenuItem
                                        to={item.link}
                                        divider
                                        selected={isActive}
                                        component={NavLink}
                                        className={
                                            isActive ? styles.active : undefined
                                        }
                                    >
                                        <ListItemIcon>{item.icon}</ListItemIcon>

                                        <ListItemText>
                                            {item.title}
                                        </ListItemText>

                                        {item.label && (
                                            <span>{item.label}</span>
                                        )}
                                    </MenuItem>
                                </li>
                            );
                        }),
                    ])}
            </MenuList>

            <div className={styles.text}>
                <span>{t('common.applicationName')}</span>
            </div>
        </Paper>
    );
};

export default Menu;
