import { cmsReadScope, settingsReadScope } from '@tools/constants';

import { Scope } from '../../tools/enums/scopes';

export enum Routes {
    LOGIN = '/login',
    AUTH = '/auth',
    BASE = '/',
    DASHBOARD = '/dashboard',
    SEARCH = '/dashboard/search/:searchQuery',
    PROFILE = '/dashboard/profile',
    PROFILE_SUMMARY = '/dashboard/profile/summary',
    PROFILE_ACTIVITY_LOG = '/dashboard/profile/activity-log',
    PROFILE_SECURITY = '/dashboard/profile/security',
    BUSINESS_REPORTS = '/dashboard/reports',
    CUSTOMERS = '/dashboard/customers',
    CUSTOMERS_LIST = '/dashboard/customers/list',
    CUSTOMERS_IMPORT_HISTORY = '/dashboard/customers/upload',
    CUSTOMER_ID = '/dashboard/customers/:customerId',
    CUSTOMER_ID_ACCOUNT = '/dashboard/customers/:customerId/account',
    CUSTOMER_ID_SMART_ENERGY = '/dashboard/customers/:customerId/smartenergy',
    CUSTOMER_ID_LIVE_STREAM = '/dashboard/customers/:customerId/live-stream',
    CUSTOMER_ID_IMPERSONATE = '/dashboard/customers/:customerId/impersonate',
    CUSTOMER_ID_SALES = '/dashboard/customers/:customerId/sales',
    CUSTOMER_ID_SALES_HISTORY = '/dashboard/customers/:customerId/sales-history',
    CUSTOMER_ID_ARCHIVED_SALES = '/dashboard/customers/:customerId/archived-sales',
    CUSTOMER_ID_AUDIT_LOG = '/dashboard/customers/:customerId/audit-log',
    CUSTOMER_ID_ALERTS = '/dashboard/customers/:customerId/alarms',
    CUSTOMER_ID_DATA_IMPORT = '/dashboard/customers/:customerId/data-import',
    CUSTOMER_ID_DATA_DEBUG = '/dashboard/customers/:customerId/data-debug',
    CUSTOMER_ID_DASHBOARD = '/dashboard/customers/:customerId/dashboard',
    CUSTOMER_ID_CUSTOMERS = '/dashboard/customers/:customerId/customers',
    CUSTOMER_ID_UPLOAD = '/dashboard/customers/:customerId/upload',
    CUSTOMER_ID_WHITE_LABEL = '/dashboard/customers/:customerId/white-label',
    DATA_STATUS = '/dashboard/data-status',
    INTEGRATION_SETTINGS = '/dashboard/settings',
    INTEGRATION_SETTINGS_APIS = '/dashboard/settings/api',
    INTEGRATION_SETTINGS_API_ACCESS = '/dashboard/settings/api-access',
    INTEGRATION_SETTINGS_TENANT_AFFILIATE = '/dashboard/settings/tenant-affiliate',
    FEATURE_GROUPS = '/dashboard/features',
    FEATURE_GROUPS_ASSIGN = '/dashboard/features/assign',
    FEATURE_GROUPS_MANAGE = '/dashboard/features/manage',
    ACCOUNT = '/dashboard/account',
    ACCOUNT_MEMBERS = '/dashboard/account/members',
    ACCOUNT_ACTIVITY_LOG = '/dashboard/account/activity-log',
    CMS = '/dashboard/cms',
    CMS_CONTENT = '/dashboard/cms/content',
    CMS_SCHEMAS = '/dashboard/cms/schemas',
    CMS_INSTRUCTIONS = '/dashboard/cms/instructions',
    UPDATE_ORDER_STATUS = '/dashboard/customers/update-order-status',
}

export interface RouteData {
    path: Routes;
    scopes: Scope[];
    isPublic: boolean;
    nested?: RouteData[];
}

export const DASHBOARD_ROUTES: RouteData[] = [
    {
        path: Routes.DASHBOARD,
        scopes: [],
        isPublic: false,
    },
    {
        path: Routes.SEARCH,
        scopes: [Scope.CUSTOMERS_CUSTOMERS_READ, Scope.CUSTOMERS_USERS_READ],
        isPublic: false,
    },
];

export const REPORTS_ROUTES: RouteData[] = [
    {
        path: Routes.BUSINESS_REPORTS,
        scopes: [Scope.BUSINESS_REPORTS_READ],
        isPublic: false,
    },
];

export const PROFILE_ROUTES: RouteData[] = [
    { path: Routes.PROFILE, scopes: [Scope.PROFILE_READ], isPublic: false },
    {
        path: Routes.PROFILE_SUMMARY,
        scopes: [Scope.PROFILE_READ],
        isPublic: false,
    },
    {
        path: Routes.PROFILE_ACTIVITY_LOG,
        scopes: [Scope.ACTIVITIES_READ],
        isPublic: false,
    },
    {
        path: Routes.PROFILE_SECURITY,
        scopes: [Scope.PROFILE_READ],
        isPublic: false,
    },
];

export const ACCOUNT_ROUTES: RouteData[] = [
    { path: Routes.ACCOUNT, scopes: [], isPublic: false },
    {
        path: Routes.ACCOUNT_ACTIVITY_LOG,
        scopes: [Scope.ACTIVITIES_READ],
        isPublic: false,
    },
    {
        path: Routes.ACCOUNT_MEMBERS,
        scopes: [Scope.ACCOUNT_USERS_READ],
        isPublic: false,
    },
];

export const CUSTOMERS_ROUTES: RouteData[] = [
    {
        path: Routes.CUSTOMERS,
        scopes: [Scope.CUSTOMERS_READ],
        isPublic: false,
    },
    {
        path: Routes.CUSTOMERS_LIST,
        scopes: [Scope.CUSTOMERS_READ],
        isPublic: false,
    },
    {
        path: Routes.CUSTOMERS_IMPORT_HISTORY,
        scopes: [Scope.CUSTOMERS_WRITE],
        isPublic: false,
    },
    {
        path: Routes.UPDATE_ORDER_STATUS,
        scopes: [Scope.CUSTOMERS_WRITE],
        isPublic: false,
    },
];

export const CUSTOMER_ID_ROUTES: RouteData[] = [
    {
        path: Routes.CUSTOMER_ID,
        scopes: [Scope.CUSTOMERS_READ],
        isPublic: false,
    },
    {
        path: Routes.CUSTOMER_ID_ACCOUNT,
        scopes: [Scope.CUSTOMERS_READ],
        isPublic: false,
    },
    {
        path: Routes.CUSTOMER_ID_SMART_ENERGY,
        scopes: [Scope.CUSTOMERS_READ],
        isPublic: false,
    },
    {
        path: Routes.CUSTOMER_ID_LIVE_STREAM,
        scopes: [Scope.LIVE_STREAM_READ],
        isPublic: false,
    },
    {
        path: Routes.CUSTOMER_ID_IMPERSONATE,
        scopes: [Scope.IMPERSONATION_READ],
        isPublic: false,
    },
    {
        path: Routes.CUSTOMER_ID_SALES,
        scopes: [Scope.CUSTOMERS_WRITE],
        isPublic: false,
    },
    {
        path: Routes.CUSTOMER_ID_SALES_HISTORY,
        scopes: [Scope.CUSTOMERS_READ],
        isPublic: false,
    },
    {
        path: Routes.CUSTOMER_ID_ARCHIVED_SALES,
        scopes: [Scope.CUSTOMERS_READ],
        isPublic: false,
    },
    {
        path: Routes.CUSTOMER_ID_AUDIT_LOG,
        scopes: [Scope.CUSTOMERS_READ],
        isPublic: false,
    },
    {
        path: Routes.CUSTOMER_ID_ALERTS,
        scopes: [Scope.CUSTOMERS_READ],
        isPublic: false,
    },
    {
        path: Routes.CUSTOMER_ID_DATA_IMPORT,
        scopes: [Scope.DATA_IMPORT_READ],
        isPublic: false,
    },
    {
        path: Routes.CUSTOMER_ID_DATA_DEBUG,
        scopes: [Scope.DATA_DEBUG_READ],
        isPublic: false,
    },
    {
        path: Routes.CUSTOMER_ID_DASHBOARD,
        scopes: [Scope.CUSTOMERS_READ],
        isPublic: false,
    },
    {
        path: Routes.CUSTOMER_ID_CUSTOMERS,
        scopes: [Scope.CUSTOMERS_READ],
        isPublic: false,
    },
    {
        path: Routes.CUSTOMER_ID_UPLOAD,
        scopes: [Scope.CUSTOMERS_WRITE],
        isPublic: false,
    },
];

export const INTEGRATION_SETTINGS_ROUTES: RouteData[] = [
    {
        path: Routes.INTEGRATION_SETTINGS,
        scopes: settingsReadScope,
        isPublic: false,
    },
    {
        path: Routes.INTEGRATION_SETTINGS_APIS,
        scopes: [Scope.SYSTEM_SETTINGS_READ],
        isPublic: false,
    },
    {
        path: Routes.INTEGRATION_SETTINGS_API_ACCESS,
        scopes: [Scope.ACCOUNT_API_ACCESS_READ],
        isPublic: false,
    },
    {
        path: Routes.INTEGRATION_SETTINGS_TENANT_AFFILIATE,
        scopes: [Scope.SYSTEM_SETTINGS_READ],
        isPublic: false,
    },
];

export const FEATURE_GROUPS_ROUTES: RouteData[] = [
    {
        path: Routes.FEATURE_GROUPS,
        scopes: [Scope.SUPPORTED_FEATURES_READ],
        isPublic: false,
    },
    {
        path: Routes.FEATURE_GROUPS_ASSIGN,
        scopes: [Scope.SUPPORTED_FEATURE_GROUP_READ],
        isPublic: false,
    },
    {
        path: Routes.FEATURE_GROUPS_MANAGE,
        scopes: [Scope.SUPPORTED_FEATURES_READ],
        isPublic: false,
    },
];

export const CMS_ROUTES: RouteData[] = [
    {
        path: Routes.CMS,
        scopes: cmsReadScope,
        isPublic: false,
    },
    {
        path: Routes.CMS_CONTENT,
        scopes: [Scope.CMS_CONTENT_READ],
        isPublic: false,
    },
    {
        path: Routes.CMS_SCHEMAS,
        scopes: [Scope.CMS_SETTINGS_READ],
        isPublic: false,
    },
    {
        path: Routes.CMS_INSTRUCTIONS,
        scopes: [Scope.CMS_CONTENT_READ],
        isPublic: false,
    },
];
