import { useCallback, useEffect, useState } from 'react';

import { Alert, AlertColor, Grow } from '@mui/material';

import useToastList, {
    ToastParams,
} from '@components/notifications/toast-notifications-provider';

type SnackbarToastProps = {
    toast: ToastParams;
};

const growInTimeout = 300;
const defaultAutoHideDurationDuration = 5000;

const Toast: React.FC<SnackbarToastProps> = ({ toast }) => {
    const [open, setOpen] = useState(true);

    const { removeToast } = useToastList();

    const { toastType, message, options } = toast || {};
    const { autoHideDuration, action } = options || {};

    const close = useCallback(() => {
        setOpen(false);
    }, [setOpen]);

    useEffect(() => {
        let handler: ReturnType<typeof setTimeout>;

        if (!open) {
            handler = setTimeout(() => {
                removeToast(toast?.key!);
            }, growInTimeout);
        }

        return () => {
            handler && clearTimeout(handler);
        };
    }, [open, removeToast, toast?.key]);

    const handleClose = (
        event: React.SyntheticEvent | Event,
        reason?: string,
    ) => {
        // reason check - to avoid Snackbar to be hidden on external click
        if (reason !== 'clickaway') {
            if (toast?.onCloseCallback) {
                toast.onCloseCallback();
            }

            close();
        }
    };

    useEffect(() => {
        let handler: ReturnType<typeof setTimeout>;

        if (autoHideDuration !== 0) {
            handler = setTimeout(() => {
                close();
            }, autoHideDuration || defaultAutoHideDurationDuration);
        }

        return () => {
            handler && clearTimeout(handler);
        };
    }, [close, autoHideDuration]);

    return (
        <Grow in={open} timeout={growInTimeout}>
            <Alert
                action={action ?? false}
                onClose={handleClose}
                severity={toastType as AlertColor}
            >
                {message}
            </Alert>
        </Grow>
    );
};

export default Toast;
