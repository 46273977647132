import { useTranslation } from 'react-i18next';

import { useMutation, useQuery, useQueryClient } from '@tanstack/react-query';

import {
    AccountApi,
    ActivityLogFilter,
    AdminUserRequestPreferencesModel,
    AdminUserResponsePreferencesModel,
    ProfileApi,
    ProfileResponseModel,
    ProfileValidateMultiFactorAuthenticationRequest,
} from '@swagger-http';

import useToastList from '@components/notifications/toast-notifications-provider';
import { createRequestConfiguration } from '@tools/utils/authentication';

interface Props {
    enabled: boolean;
}

export const useGetProfileQuery = ({ enabled }: Props) => {
    return useQuery<ProfileResponseModel, Error>(
        ['userProfile'],
        async () => {
            const response = await new ProfileApi(
                createRequestConfiguration(),
            ).profileGetProfile();
            return response;
        },
        {
            enabled,
        },
    );
};

export const useGetProfilePreferencesQuery = ({ enabled }: Props) => {
    return useQuery<AdminUserResponsePreferencesModel, Error>(
        ['userPreferences'],
        async () => {
            const response = await new ProfileApi(
                createRequestConfiguration(),
            ).profileGetUserPreferences();
            return response;
        },
        {
            enabled,
        },
    );
};

export const useGetProfileMFA = () =>
    useQuery({
        queryKey: ['getProfileMFA'],
        queryFn: async () => {
            return await new ProfileApi(
                createRequestConfiguration(),
            ).profileStartMultiFactorAuthentication();
        },
    });

export const useProfileValidateMFA = () => {
    const { t } = useTranslation();
    const queryClient = useQueryClient();
    const { showToast } = useToastList();

    return useMutation({
        mutationKey: ['profileValidateMFA'],
        mutationFn: async (
            payload: ProfileValidateMultiFactorAuthenticationRequest,
        ) => {
            const response = new ProfileApi(
                createRequestConfiguration(),
            ).profileValidateMultiFactorAuthentication(payload);

            return response;
        },
        onSuccess: () => {
            queryClient.invalidateQueries({ queryKey: ['userProfile'] });

            showToast({
                message: t('profile.security.activated2FA'),
                toastType: 'success',
            });
        },
    });
};

export const useProfileRemoveMFA = () => {
    const { t } = useTranslation();
    const queryClient = useQueryClient();
    const { showToast } = useToastList();

    return useMutation({
        mutationKey: ['profileRemoveMFA'],
        mutationFn: async () => {
            const response = new ProfileApi(
                createRequestConfiguration(),
            ).profileDeleteMultiFactorAuthentication();

            return response;
        },
        onSuccess: () => {
            queryClient.invalidateQueries({ queryKey: ['userProfile'] });

            showToast({
                message: t('profile.security.removed2FA'),
                toastType: 'success',
            });
        },
    });
};

const updateUserPreferences = async (
    preferences: AdminUserRequestPreferencesModel,
): Promise<AdminUserResponsePreferencesModel> => {
    const requestBody = {
        adminUserRequestPreferencesModel: preferences!,
    };

    const data = await new ProfileApi(
        createRequestConfiguration(),
    ).profileUpdateUserPreferences(requestBody);

    return data;
};

export const useMutateUpdateUserPreferences = (retry?: boolean) => {
    return useMutation({
        mutationKey: ['updateUserPreferences'],
        retry,
        mutationFn: updateUserPreferences,
    });
};

export const useAccountActivityLogs = (
    filter?: ActivityLogFilter,
    page?: number,
    limit?: number,
) =>
    useQuery({
        queryKey: ['accountActivityLogs'],
        queryFn: async () =>
            await new AccountApi(
                createRequestConfiguration(),
            ).accountGetAuditLogsForAccount({
                filter: filter as ActivityLogFilter,
                page,
                limit,
            }),
    });
