import {
    createContext,
    Dispatch,
    FC,
    PropsWithChildren,
    SetStateAction,
    useContext,
    useMemo,
    useState,
} from 'react';

interface IApiError {
    name?: string;
    message?: string;
    description?: string;
    status: string | number;
    // could be string, could be object with n keys
    response?: any;
}

export interface IStatusContext {
    setIsLoading: Dispatch<SetStateAction<boolean>>;
    setError: Dispatch<SetStateAction<IApiError | Error | null>>;
    isLoading: boolean;
    error: IApiError | Error | null;
}

const StatusContext = createContext<IStatusContext | undefined>(undefined);

export const StatusContextProvider: FC<PropsWithChildren> = ({ children }) => {
    const [isLoading, setIsLoading] = useState<boolean>(false);
    const [error, setError] = useState<IApiError | Error | null>();

    const value: IStatusContext = useMemo(() => {
        return { isLoading, setIsLoading, error, setError } as IStatusContext;
    }, [isLoading, setIsLoading, error, setError]);

    return (
        <StatusContext.Provider value={value}>
            {children}
        </StatusContext.Provider>
    );
};

const useGlobalStatus = () => {
    const context = useContext(StatusContext);
    if (context === undefined) {
        throw new Error(
            '`useStatusContext` must be used within `StatusContextProvider`',
        );
    }

    return context;
};

export default useGlobalStatus;
