import { AuthorizationResponseModel } from '@swagger-http';

import { scopes as hardCodedScopes } from '@config/env';
import { STORAGE_KEYS_PREFIX } from '@hooks/use-local-storage';
import { DEBUG } from '@tools/constants';
import { Scope, ScopeType } from '@tools/enums';

export const getScopes = (): ScopeType => {
    let storedScopes: AuthorizationResponseModel[] = [];

    try {
        const storage = localStorage.getItem(`${STORAGE_KEYS_PREFIX}-session`);

        storedScopes = JSON.parse(storage ?? '{}')?.scopes ?? [];
    } catch (e) {
        // Silently fail.
    }

    if (storedScopes.length) {
        return storedScopes.map((item) => `${item.action}:${item.resource}`);
    }

    try {
        return JSON.parse(hardCodedScopes);
    } catch (e) {
        return [];
    }
};

export const checkForScopes = (
    needles: ScopeType,
    fullMatch = false,
): boolean => {
    const haystack: ScopeType = getScopes();
    const result = fullMatch
        ? needles.every((scope: Scope) => haystack.includes(scope))
        : !!needles.filter((needle: Scope) => haystack.includes(needle)).length;

    const shouldDebug = localStorage.getItem(DEBUG);

    if (!result && shouldDebug === 'true') {
        // eslint-disable-next-line no-console
        console.trace('Missing permissions: ', needles);
    }

    return result;
};
