import {
    createContext,
    FC,
    PropsWithChildren,
    useCallback,
    useContext,
    useEffect,
    useMemo,
    useState,
} from 'react';

import {
    AdminUserRequestPreferencesModel,
    AdminUserResponsePreferencesModel,
    PreferencesModel,
    ProfileResponseModel,
} from '@swagger-http';

import {
    useGetProfilePreferencesQuery,
    useGetProfileQuery,
    useMutateUpdateUserPreferences,
} from '@queries/profile';

import useAuthContext from '@context/auth-provider';

interface IApiError {
    name?: string;
    message?: string;
    description?: string;
    status: string | number;
    // could be string, could be object with n keys
    response?: any;
    cause?: any;
}

interface IUserContext {
    userData: ProfileResponseModel;
    userPreferences: AdminUserResponsePreferencesModel;
    isLoading: boolean;
    isUpdatingUserPreferences: boolean;
    errorUpdateUserPreferences: any;
    error: IApiError | null;
    handleUpdateUserPreferences: (data: PreferencesModel) => Promise<void>;
    isAdmin: boolean;
    isRoot: boolean;
    isSupport: boolean;
}

const UserContext = createContext<IUserContext | undefined>(undefined);

enum Role {
    ADMIN = 'admin',
    ROOT = 'root',
    SUPPORT = 'support',
}

export const UserContextProvider: FC<PropsWithChildren> = ({ children }) => {
    const { isLoggedIn } = useAuthContext();

    const [userPreferences, setUserPreferences] =
        useState<AdminUserResponsePreferencesModel | null>(null);

    const {
        data: userData,
        error: errorUserProfile,
        isLoading: isLoadingUserProfile,
    } = useGetProfileQuery({ enabled: isLoggedIn });

    const {
        data: initialUserPreferences,
        error: errorUserPreferences,
        isLoading: isLoadingUserPreferences,
    } = useGetProfilePreferencesQuery({ enabled: isLoggedIn });

    const {
        mutateAsync: updateUserPreferences,
        error: errorUpdateUserPreferences,
        isLoading: isUpdatingUserPreferences,
    } = useMutateUpdateUserPreferences();

    const getPreferenceModel = useCallback(
        (data: PreferencesModel | any) => {
            return {
                ...initialUserPreferences,
                preferences: {
                    ...initialUserPreferences?.preferences,
                    ...data,
                },
            } as AdminUserRequestPreferencesModel;
        },
        [initialUserPreferences],
    );

    const handleUpdateUserPreferences = useCallback(
        async (data: PreferencesModel) => {
            let result: AdminUserResponsePreferencesModel | null = null;
            try {
                const requestValue = getPreferenceModel(data);
                result = await updateUserPreferences(requestValue);
            } catch (error) {
                // technically should've been catched in useQuery implementation -> errorUpdateUserPreferences
                // eslint-disable-next-line no-console
                console.error(
                    'user-provider::handleUpdateUserPreferences:error',
                    error,
                );
            } finally {
                setUserPreferences((prev) => result ?? prev);
            }
        },
        [getPreferenceModel, setUserPreferences, updateUserPreferences],
    );

    useEffect(() => {
        if (initialUserPreferences) {
            setUserPreferences(initialUserPreferences);
        }
    }, [initialUserPreferences, setUserPreferences]);

    const isLoading = useMemo<boolean>(
        () =>
            [isLoadingUserPreferences, isLoadingUserProfile].some(
                (item) => item === true,
            ),
        [isLoadingUserPreferences, isLoadingUserProfile],
    );

    const error = useMemo<IApiError>(
        () =>
            // TODO: should we have only one at a time or full array?: EONFEH-16031
            [
                errorUserProfile,
                errorUserPreferences,
                errorUpdateUserPreferences,
            ].find((item) => item !== null) as IApiError,
        [errorUserProfile, errorUserPreferences, errorUpdateUserPreferences],
    );

    const isRole = useCallback(
        (role: Role) => userData?.roles?.[0] === role,
        [userData?.roles],
    );

    const [isAdmin, isRoot, isSupport] = useMemo(
        () => [isRole(Role.ADMIN), isRole(Role.ROOT), isRole(Role.SUPPORT)],
        [isRole],
    );

    const value = useMemo<IUserContext>(() => {
        return {
            userData,
            userPreferences,
            isLoading,
            handleUpdateUserPreferences,
            isUpdatingUserPreferences,
            errorUpdateUserPreferences,
            error: error ?? null,
            isAdmin,
            isRoot,
            isSupport,
        } as IUserContext;
    }, [
        userData,
        userPreferences,
        isLoading,
        handleUpdateUserPreferences,
        isUpdatingUserPreferences,
        errorUpdateUserPreferences,
        error,
        isAdmin,
        isRoot,
        isSupport,
    ]);

    return (
        <UserContext.Provider value={value}>{children}</UserContext.Provider>
    );
};

const useUserContext = () => {
    const context = useContext(UserContext);
    if (context === undefined) {
        throw new Error(
            '`useUserContext` must be used within `UserContextProvider`',
        );
    }

    return context;
};

export default useUserContext;
