import { useTranslation } from 'react-i18next';

import {
    useMutation,
    useQuery,
    useQueryClient,
    UseQueryResult,
} from '@tanstack/react-query';

import {
    IoTVendorPostModel,
    SettingsApi,
    SettingsTenantVendorAllocateRequest,
    SettingsUpdateVendorRequest,
} from '@swagger-http';
import {
    IoTVendorCategoryResponseModel,
    IoTVendorResponseModel,
    IoTVendorTenantsResponseModel,
    SettingsDeleteVendorRequest,
} from '@swagger-http';

import useToastList from '@components/notifications/toast-notifications-provider';
import { createRequestConfiguration } from '@tools/utils/authentication';

export const useGetVendorsHealth = (
    enabled?: boolean,
): UseQueryResult<IoTVendorResponseModel[]> =>
    useQuery({
        queryKey: ['getVendorsHealth'],
        enabled,
        queryFn: async () =>
            await new SettingsApi(
                createRequestConfiguration(),
            ).settingsGetVendorsHealth(),
    });

export const useGetCategoriesWithVendors =
    (): UseQueryResult<IoTVendorCategoryResponseModel> =>
        useQuery({
            queryKey: ['getCategoriesWithVendors'],
            queryFn: async () =>
                await new SettingsApi(
                    createRequestConfiguration(),
                ).settingsGetVendorsCategories(),
        });

export const useGetTenantVendorAllocation = (
    id: string,
    enabled?: boolean,
): UseQueryResult<IoTVendorTenantsResponseModel> =>
    useQuery({
        queryKey: ['getTenantVendorAllocations', id],
        enabled: enabled,
        queryFn: async () =>
            await new SettingsApi(
                createRequestConfiguration(),
            ).settingsGetTenantVendorAllocation({ vendorId: id }),
    });

export const useDeleteVendorMutation = () => {
    const { showToast } = useToastList();
    const { t } = useTranslation();

    const queryClient = useQueryClient();
    return useMutation({
        mutationKey: ['deleteVendor'],
        mutationFn: async (payload: SettingsDeleteVendorRequest) => {
            return await new SettingsApi(
                createRequestConfiguration(),
            ).settingsDeleteVendor(payload);
        },
        onSuccess: () => {
            queryClient.invalidateQueries({
                queryKey: ['getVendorsHealth'],
            });

            showToast({
                toastType: 'success',
                message: t(
                    'settings.thirdPartyAPIs.removeModal.successMessage',
                ),
            });
        },
    });
};

export const useCreateVendor = () => {
    const queryClient = useQueryClient();

    return useMutation({
        mutationKey: ['createVendor'],
        mutationFn: async (payload: IoTVendorPostModel) => {
            return await new SettingsApi(
                createRequestConfiguration(),
            ).settingsCreateVendor({
                ioTVendorPostModel: payload,
            });
        },
        onSuccess: () => {
            queryClient.invalidateQueries({
                queryKey: ['getVendorsHealth'],
            });
        },
    });
};

export const useUpdateVendor = () => {
    const queryClient = useQueryClient();

    return useMutation({
        mutationKey: ['updateVendor'],
        mutationFn: async (payload: SettingsUpdateVendorRequest) => {
            return new SettingsApi(
                createRequestConfiguration(),
            ).settingsUpdateVendor(payload);
        },
        onSuccess: () => {
            queryClient.invalidateQueries({ queryKey: ['getVendorsHealth'] });
        },
    });
};

export const useUpdateVendorAllocate = () => {
    const queryClient = useQueryClient();

    return useMutation({
        mutationKey: ['updateVendorAllocate'],
        mutationFn: async (payload: SettingsTenantVendorAllocateRequest) => {
            return new SettingsApi(
                createRequestConfiguration(),
            ).settingsTenantVendorAllocate(payload);
        },
        onSuccess: () => {
            queryClient.invalidateQueries({
                queryKey: ['getTenantVendorAllocations'],
            });
        },
    });
};
