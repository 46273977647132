import { FC, PropsWithChildren, useCallback, useEffect } from 'react';

import { useTranslation } from 'react-i18next';
import { useLocation } from 'react-router';

import fetchIntercept, { FetchInterceptorResponse } from 'fetch-intercept';

import { Routes } from '@api/tools';

import useToastList from '@components/notifications/toast-notifications-provider';
import { extractErrorMessage } from '@tools/utils/debug';

import * as ENV from '@config/env';
import useAuthContext from '@context/auth-provider';
import useLocalStorage from '@hooks/use-local-storage';
import { TARGET_ROUTE } from '@tools/constants';

const signOutRoute = '/auth/sign-out';
const multifactorAuthRoute = 'auth/sign-in/multifactor';
const excludedAuthHeaderRoutes = ['/auth/sign-in'];
const excludedRedirectRoutes = [
    '/auth/sign-out',
    '/auth/sign-in',
    '/cms/schemas',
    '/cms/content',
];

const urlIsExcluded = (url: string) =>
    excludedRedirectRoutes.some((route) => url.includes(route));

const shouldSkipAuthHeader = (url: string) =>
    excludedAuthHeaderRoutes.some((route) => url?.endsWith(route));

const isMultifactorAuthRoute = (url: string) =>
    url.endsWith(multifactorAuthRoute);

export const FetchInterceptor: FC<PropsWithChildren> = ({ children }) => {
    const { t } = useTranslation();
    const location = useLocation();
    const { showToast } = useToastList();

    const { getStorageValue: getStoreTempSessionValue } = useLocalStorage(
        'temp-session',
        null,
    );
    const { getStorageValue: getStoreSessionValue } = useLocalStorage(
        'session',
        null,
    );

    const { signOut } = useAuthContext();

    const withAuthorizationHeader = useCallback(
        (url: string, headers: Headers): Headers => {
            const session = isMultifactorAuthRoute(url)
                ? getStoreTempSessionValue()
                : getStoreSessionValue() ?? null;

            // keeping auth token in case it has been provided manually
            // e.g. we do in getApiV2PvBatteryEfficiencyCheck call by providing customers token manually
            if (
                //@ts-ignore
                (headers['Authorization'] as keyof typeof Headers) &&
                //@ts-ignore
                headers?.['Authorization']?.length
            ) {
                return headers;
            }

            const accessToken =
                ENV.accessToken || (session as any)?.accessToken || '';

            const updatedHeaders = {
                ...headers,
                ...(session
                    ? {
                          Authorization: `Bearer ${accessToken}`,
                      }
                    : {}),
            };

            return updatedHeaders as Headers;
        },
        [getStoreSessionValue, getStoreTempSessionValue],
    );

    const tryLogoutIfUnauthorized = useCallback(
        (response: Response) => {
            const unauthorizedPath = location.pathname;
            if (
                unauthorizedPath !== Routes.LOGIN &&
                response?.status === 401 &&
                !urlIsExcluded(response?.url)
            ) {
                localStorage.setItem(TARGET_ROUTE, unauthorizedPath);
                signOut();
            }
        },
        [signOut, location.pathname],
    );

    const requestInterceptor = useCallback(
        (url: string, config: any) => {
            config.headers = {
                ...config.headers,
                ...(shouldSkipAuthHeader(url || '')
                    ? {}
                    : withAuthorizationHeader(url, config.headers!)),
            };

            if (url?.endsWith(signOutRoute)) {
                config.data = JSON.stringify({});
            }

            return [url, config];
        },
        [withAuthorizationHeader],
    );

    const requestErrorInterceptor = useCallback((error: any) => {
        return Promise.reject(error);
    }, []);

    const responseErrorInterceptor = useCallback(
        async (responseError: any) => {
            tryLogoutIfUnauthorized(responseError?.response || responseError);

            const errorMessage =
                responseError.status === 401
                    ? t('common.sessionExpired')
                    : typeof responseError.clone === 'function'
                      ? extractErrorMessage(await responseError.clone().text())
                      : '';

            const message = errorMessage || t('common.genericErrorTitle');

            showToast({
                toastType: 'error',
                message: message,
                key: message,
            });

            return await responseError;
        },
        [showToast, tryLogoutIfUnauthorized, t],
    );

    const responseInterceptor = useCallback(
        (response: FetchInterceptorResponse) => {
            if (response.status === 401) {
                tryLogoutIfUnauthorized(response);
            }

            // Ignore showing 404 errors for now
            if (response.status === 404) return response;

            if (!response.ok) {
                responseErrorInterceptor(response);
            }

            return response;
        },
        [responseErrorInterceptor, tryLogoutIfUnauthorized],
    );

    useEffect(() => {
        const unregister = fetchIntercept.register({
            request: requestInterceptor,
            requestError: requestErrorInterceptor,
            response: responseInterceptor,
            responseError: responseErrorInterceptor,
        });

        return () => {
            unregister();
        };
    }, [
        requestErrorInterceptor,
        requestInterceptor,
        responseInterceptor,
        responseErrorInterceptor,
    ]);

    return <>{children}</>;
};
